/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {Alert} from '@material-ui/lab';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    code: "code",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Overview"), "\n", React.createElement(_components.p, null, "In an ideal world, the user accepts the purposes and 3rd parties a vendor uses, and the choices are made effective immediately."), "\n", React.createElement(_components.p, null, "Signatu provides a banner to provide these choices to the user (see ", React.createElement(_components.a, {
    href: "/docs/guides/cookiebanner"
  }, "Cookie Banner"), "). When the user has made their choice,\nSignatu sends the consent event to the website, both within the user’s browser and server-side. So far so good."), "\n", React.createElement(_components.p, null, "However, what happens then is more complicated. The way website load scripts differ wildly. Some website uses a CMS such as Wordpress to\ninject some scripts on the server side, some load scripts directly in the Web browser using e.g., a Tag Manager or custom scripts, others use both\nserver-side and client-side injection."), "\n", React.createElement(_components.p, null, "Signatu deploys several strategies to help you unload and/or block scripts based on the user choices. This page describes these strategies, and\ntheir limitations. Be aware that to achieve full cookie consent compliance may require you to make changes to your website."), "\n", React.createElement(_components.h2, {
    id: "consent-event",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#consent-event",
    "aria-label": "consent event permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Consent Event"), "\n", React.createElement(_components.p, null, "Signatu will ", React.createElement(_components.code, null, "postMessage"), " the current Consent Event to the main ", React.createElement(_components.code, null, "window"), " when loaded from the Consent API, retrieved from browser cache, or the user\nmakes a change to their settings. Websites can use this event to evaluate which resources it can load. For most small websites, designing custom\ncode for this is not feasible, so an automated (but limited, see below) approach can be deployed instead."), "\n", React.createElement(_components.h2, {
    id: "dom-controller",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dom-controller",
    "aria-label": "dom controller permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "DOM Controller"), "\n", React.createElement(_components.p, null, "The Document Object Model (DOM) is the internal structure of a web page, which contains instructions to the browser on what scripts,\niframes and pixels to load. Signatu will monitor changes to the DOM. When a script, iframe or pixel is injected into the DOM that is\nis not allowed according to the Signatu Consent Engine (e.g., because the user has refused), Signatu will try to prevent the script from loading.\nWe use two methods for this - overriding the browser’s ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/Document/createElement"
  }, React.createElement(_components.code, null, "createElement")), " method,\nand listening to DOM changes using ", React.createElement(_components.a, {
    href: "https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver"
  }, React.createElement(_components.code, null, "MutationObserver")), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 449px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 82.5%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAABu0lEQVQ4y52T125CQQxE7/9/FQ/UBxBNCEQH0XvvTHQcLbpsACWxZO2Cr8djjzdoNBpqt9vqdDqq1+uqVCpar9e6Xq86n89/8svlogCwXq+n0WikwWCgZrOp+Xyuw+Gg7Xb75Lvd7q3v93tNJhMF9/vdGM1mM91uN/P/GkQCULvdrjn35XJp7QIM81qtplarpel0akmfChogLTM7ADkBJIkzkUgoHo8rmUyqXC6LbrDj8ajT6WRj4aRdYovFQgFB5sMP7nyEuWR3JwZzCqXTaSsSi8VULBaVyWRMA8YWkADIZrN5AnDu4nTASevcI5GIAWezWVWrVcsfDoffgFRdrVYfB+6YY7TI94Dg5D9aZv8YPPtIBVeAJLdKiIVA/X7fvmHn3ooC3Vwup1QqZWpSkbYALZVKJkZ46TnDbH8Ast2wYTm5hy0c42MUxf2FRtQHoC+KL4iz8XhsSjO7fD6vQqGgaDRqJyNjLA9AXgoeBvTv7vmRCAFaR2GcmSII7A2QP6D+CtDfyXBHtMxJIfeCDJCAY+gCr0BfjSJsxAJWwT0/VgJ1nTj+a/GBfTeGgLBvbD+Dhy2zeNXqb+wL678dDLaf8PwAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"DOM Controller\"\n        title=\"DOM Controller\"\n        src=\"/static/806102e344973481aa9bc173d9dfe477/d4e15/domcontroller.png\"\n        srcset=\"/static/806102e344973481aa9bc173d9dfe477/f8f3a/domcontroller.png 200w,\n/static/806102e344973481aa9bc173d9dfe477/6a8a8/domcontroller.png 400w,\n/static/806102e344973481aa9bc173d9dfe477/d4e15/domcontroller.png 449w\"\n        sizes=\"(max-width: 449px) 100vw, 449px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(Alert, {
    severity: "warning"
  }, React.createElement(_components.p, null, "The observant reader will notice that the Signatu DOM Controller code must be active before it can do any blocking, and any resources loaded before\nSignatu is active can potentially send data to 3rd parties. So in order to ensure that all scripts are being controlled Signatu must be allowed to\nfully load before any other scripts in the user’s browser. This may or may not be possible in practice, depending on your website implementation. It will\nalso introduce a delay while the Signatu code initializes and evaluates the Consent status for the user.")), "\n", React.createElement(_components.h3, {
    id: "startup",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#startup",
    "aria-label": "startup permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Startup"), "\n", React.createElement(_components.p, null, "When the DOM Controller runs it will initialize by analyzing the DOM resources and tag resources. If resources found should be blocked, Signatu will try to\nunload the tag by removing it from the DOM and re-injecting it with the blocked flag(s) set. Depending on the browser implementation this may or may not\ndisable the script in the current session."), "\n", React.createElement(_components.h2, {
    id: "tagger",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tagger",
    "aria-label": "tagger permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tagger"), "\n", React.createElement(_components.p, null, "When the DOM Controller detects requested changes in the DOM, e.g., a new script, it does not block it directly. Instead, it allows the resource\nto exist in the DOM, but will annotate the element with tags and change or remove the ", React.createElement(_components.code, null, "src"), " and ", React.createElement(_components.code, null, "type"), " tags to prevent loading. If the user\nmakes changes to their setting Signatu will reverse this process, allowing the resource to load again."), "\n", React.createElement(_components.h2, {
    id: "blocker",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#blocker",
    "aria-label": "blocker permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Blocker"), "\n", React.createElement(_components.p, null, "The Signatu Blocker will evaluate the user’s Consent event against a Cookie specification created by Trackerdetect, and determine whether a resource\n(script, iframe, pixel) can be loaded. If blocked, the blocker will mark up the resource with ", React.createElement(_components.code, null, "data-*"), " tags, and move the ", React.createElement(_components.code, null, "src"), " tag to ", React.createElement(_components.code, null, "data-src"), " to\ndefer loading of the resource. Here’s an example for ", React.createElement(_components.code, null, "Google Recaptcha"), " blocked by the blocker:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<script\n    type=\"text/plain\"\n    data-service=\"googlerecaptcha\"\n    data-autotagged=\"true\"\n    data-blocked=\"true\"\n    data-src=\"https://www.google.com/recaptcha/api.js\"\n></script>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
